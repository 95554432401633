import React, { useEffect, useReducer, useState } from 'react'
import '../../styles/pages/add-new.scss';
import Textfield from '../../components/Textfield';
import Button from '../../components/Button';
import Textarea from '../../components/Textarea';
import SelectButton from '../../components/SelectButton';
import Select from '../../components/Select';

import Header from '../../containers/header/Header';
import Footer from '../../containers/footer/Footer';

import { useNavigate, useParams, Link } from "react-router-dom";
import ImageUpload from './ImageUpload';
import { useTranslation } from 'react-i18next';

import { useError } from '../../context/ErrorContext';

import client from '../../client';
import { createItem, uploadFiles } from '@directus/sdk';

import i18n from '../../i18n';
import Checkboxes from '../../components/Checkboxes';

function getUUID() {
    const urlParams = new URLSearchParams(window.location.search);
    let uuid = urlParams.get('uuid');

    if (!uuid) {
        uuid = localStorage.getItem('uuid');
    } else {
        localStorage.setItem('uuid', uuid);
    }

    return uuid;
}

const options = [
    { value: 'none', text: 'Organisationsform', disabled: 'disabled', selected: 'defaultValue', hidden: 'hidden' },
    {
        value: 'Verein tätig im Radsport', text: 'Verein tätig im Radsport'
    },
    { value: 'Swiss Cycling Guide', text: 'Swiss Cycling Guide' },
    { value: 'Swiss Cycling Guide Partnerbetrieb', text: 'Swiss Cycling Guide Partnerbetrieb' },
]

const kantone = [
    { value: 'none', text: 'Wohnkanton', disabled: 'disabled', selected: 'defaultValue', hidden: 'hidden' },
    { value: 'Aargau', text: 'Aargau' },
    { value: 'Appenzell Ausserrhoden', text: 'Appenzell Ausserrhoden' },
    { value: 'Appenzell Innerrhoden', text: 'Appenzell Innerrhoden' },
    { value: 'Basel-Landschaft', text: 'Basel-Landschaft' },
    { value: 'Basel-Stadt', text: 'Basel-Stadt' },
    { value: 'Bern', text: 'Bern' },
    { value: 'Freiburg', text: 'Freiburg' },
    { value: 'Genf', text: 'Genf' },
    { value: 'Glarus', text: 'Glarus' },
    { value: 'Graubünden', text: 'Graubünden' },
    { value: 'Jura', text: 'Jura' },
    { value: 'Luzern', text: 'Luzern' },
    { value: 'Neuenburg', text: 'Neuenburg' },
    { value: 'Nidwalden', text: 'Nidwalden' },
    { value: 'Obwalden', text: 'Obwalden' },
    { value: 'Schaffhausen', text: 'Schaffhausen' },
    { value: 'Schwyz', text: 'Schwyz' },
    { value: 'Solothurn', text: 'Solothurn' },
    { value: 'St. Gallen', text: 'St. Gallen' },
    { value: 'Tessin', text: 'Tessin' },
    { value: 'Thurgau', text: 'Thurgau' },
    { value: 'Uri', text: 'Uri' },
    { value: 'Waadt', text: 'Waadt' },
    { value: 'Wallis', text: 'Wallis' },
    { value: 'Zug', text: 'Zug' },
    { value: 'Zürich', text: 'Zürich' },
]


const sportarten = [
    "Bahn",
    "BMX",
    "MTB",
    "MTB Gravity",
    "Radquer",
    "Strasse",
    "Trial"
];

const js_ausbildung = [
    "J+S WB2-L",
    "Trainer/in Leistungssport mit eidg. Fachausweis",
    "Trainer/in Spitzensport mit eidg. Diplom",
];

const angebot = [
    "Individuelle Trainings- & Wettkampfanalyse, -planung & -steuerung",
    "Bereatungsgespräche",
    "Leistungsdiagnostik",
    "Individuelle Trainings (z.B. Techniktrainings)",
    "Gruppentrainings",
    "Begleitung an Wettkämpfe",
    "Krafttraining",

];


const initialState = {
    vorname: '',
    nachname: '',
    email: '',
    organisation: '',
    titel: '',
    beschreibung: '',
    type: 'suche',
    adresse: '',
    plz: '',
    ort: '',
    termine: '',
    js_ausbildung: [],
    scg_ausbildung: [],

}

function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state, [action.key]: action.value,
            }
        case 'REPLACE':
            return action.payload;
        default:
            return state
    }
}

const checkIfNotEmpty = (state) => {


    if (state.vorname === '' ||
        state.nachname === '' ||
        state.email === '' ||
        (state.organisation === '' && state.type === 'suche') ||
        (state.organisationsform === 'none' && state.type === 'suche') ||
        state.adresse === '' ||
        state.plz === '' ||
        state.ort === '' ||
        state.titel === '' ||
        state.beschreibung === '' ||
        (state.js_ausbildung.length === 0 && state.scg_ausbildung.length === 0) ||
        state.sportart.length === 0 ||
        state.availability.length === 0) {
        return false;
    }

    return true;
}

const erfahrung_options = [
    "Trainer/in von Jugendlichen (ab U17)",
    "Trainer/in von erwachsenen Breiten- & Leistungssportlern",
    "Trainer/in von Paracycling-Athleten",
]


// const isProduction = process.env.REACT_APP_IS_PRODUCTION;
const isProduction = false;

export default function AddNew() {

    const { t } = useTranslation('erfassen-seite');

    const { language } = useParams();

    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { setErrorMessage, setSuccessMessage } = useError();

    const { id } = useParams();


    useEffect(() => {
        const uuid = getUUID();

        if (!uuid) {
            console.error('UUID is missing');
            return;
        }



        const fetchData = async () => {
            if (id === undefined) return;
            console.log(id)
            try {
                const payload = { uuid };

                const response = await fetch('https://api.swiss-cycling-jobboerse.ch/jobboerse/get', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const items = await response.json();

                const item = items.filter(item => item.id === parseInt(id));
                console.log(item)
                setReducerState(item[0]);

                //setData(items);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    const customJSONParse = (data) => {
        if (data.includes('[')) {
            return JSON.parse(data);
        } else {
            return [data];
        }
    }

    const setReducerState = (data) => {
        const tmpData = {
            ...data,
            js_ausbildung: customJSONParse(data.js_ausbildung),
            sportart: customJSONParse(data.sportart),
            scg_ausbildung: customJSONParse(data.scg_ausbildung),
            availability: customJSONParse(data.availability),
        }

        dispatch({
            type: 'REPLACE',
            payload: tmpData,
        })
    }




    const addFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        const result = await client.request(uploadFiles(formData));

        return result;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();


        // if (!checkIfNotEmpty(reducerState)) {
        //     setErrorMessage('Bitte fülle alle Felder aus.');
        //     return;
        // }

        // if (reducerState.beschreibung.length < 50) {
        //     setErrorMessage('Die Beschreibung sollte mindestens 50 Zeichen lang sein.');
        //     return;
        // }

        // if (!reducerState.datenschutz) {
        //     setErrorMessage('Um deinen Eintrag einzureichen, musst du die Datenschutzbestimmungen akzeptieren.');
        //     return;
        // }

        let file_id = null;
        if (reducerState.file) {
            const response = await addFile(reducerState.file);
            file_id = response.id;
        }


        const createPost = () => {
            client.request(createItem('trainer_items', { ...reducerState, language: i18n.language, image: file_id }))
                .then(result => {
                    if (isProduction) {
                        let _paq = window._paq = window._paq || [];
                        _paq.push(['trackEvent', 'Neuer Eintrag', 'Neuer Eintrag', reducerState.type])
                    }
                    setSuccessMessage('Erfolgreich gespeichert.');
                    // navigate('/success')
                })
                .catch(error => {
                    setErrorMessage(error.message);
                });
        }

        const updatePost = async (reducerState, file_id) => {

            const uuid = localStorage.getItem('uuid');

            const payload = { ...reducerState };
            if (file_id) {
                payload.image = file_id;
            }


            const data = {
                payload: payload,
                uuid: uuid,
                id: id,
            };

            try {
                const response = await fetch('https://api.swiss-cycling-jobboerse.ch/jobboerse/put', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                if (response.ok) {
                    console.log('Post updated successfully');
                    setSuccessMessage('Erfolgreich gespeichert.');
                } else {
                    console.error('Failed to update post');
                    setErrorMessage('Failed to update post');
                }
            } catch (error) {
                console.error('Error updating post:', error);
                setErrorMessage('Failed to update post');

            }
        };

        if (id === undefined) {
            createPost();
        } else {
            updatePost(reducerState, file_id);
        }



    }

    useEffect(() => {


        if (reducerState.type === 'biete') {
            dispatch({
                type: 'UPDATE',
                value: t('biete_titel_placeholder'),
                key: 'titel',
            })
        } else {
            dispatch({
                type: 'UPDATE',
                value: '',
                key: 'titel',
            })
        }

    }, [reducerState.type])

    useEffect(() => {

        if (reducerState.plz.length < 4 || reducerState.plz.length > 4) {
            return;
        }

        const fetchPlz = async () => {
            fetch(`https://nominatim.openstreetmap.org/search?postalcode=${reducerState.plz}&country=Switzerland&format=json&addressdetails=1`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.length > 0) {
                    dispatch({
                        type: 'UPDATE',
                        value: data[0].address.state,
                        key: 'kanton',
                    })
                    const coordinates = { "type": "Point", "coordinates": [parseFloat(data[0].lon), parseFloat(data[0].lat)] }
                    dispatch({
                        type: 'UPDATE',
                        value: coordinates,
                        key: 'coordinates',
                    })
                }
            }).catch((err) => {
                console.log(err);
            });
        }

        fetchPlz();



    }, [reducerState.plz])

    const deleteEntry = async () => {



        const uuid = localStorage.getItem('uuid');

        const payload = { status: 'deleted' };

        const data = {
            payload: payload,
            uuid: uuid,
            id: id,
        };

        try {
            const response = await fetch('https://api.swiss-cycling-jobboerse.ch/jobboerse/put', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                setSuccessMessage('Erfolgreich gelöscht.');
                navigate('/de/eintrag-bearbeiten')

            } else {
                console.error('Failed to update post');
                setErrorMessage('Eintrag konnte nicht gelöscht werden.');
            }
        } catch (error) {
            console.error('Error updating post:', error);
            setErrorMessage('Failed to update post');

        }
    }



    return (<>
        <Header />

        <div className='add-new content'>
            <div className="content">

                {id === undefined ?
                    <div>
                        <h2>{t('erfassungsregeln_titel')}</h2>
                        <p>{t('trainerfinder_text')}</p>
                    </div>
                    : null}




                <form onSubmit={e => { handleSubmit(e) }}>
                    <h2>{t('kontakdaten_titel')}</h2>
                    <p>{t('kontakdaten_text')}</p>
                    <div className="columns-2">
                        <Textfield
                            value={reducerState.vorname}
                            onChange={(e) =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'vorname',
                                })}
                            placeholder={t('vorname')} />
                        <Textfield
                            value={reducerState.nachname}
                            onChange={(e) =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'nachname',
                                })}
                            placeholder={t('nachname')} />
                    </div>
                    <Textfield
                        value={reducerState.email}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'email',
                            })}
                        placeholder={t('Email')} />
                    <div>




                        <Select options={kantone} value={reducerState.wohnkanton} onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'wohnkanton',
                            })} />
                    </div>
                    <Textfield
                        value={reducerState.wohnort}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'wohnort',
                            })}
                        placeholder={t('wohnort')} />

                    <Textfield
                        value={reducerState.webseite}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'webseite',
                            })}
                        placeholder={t('webseite')} />
                    <Textfield
                        value={reducerState.telefon}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'telefon',
                            })}
                        placeholder={t('telefon')} />
                    <div>

                        <h2>{t('trainerfinder_bild_titel')}</h2>
                        <p>{t('trainerfinder_bild_text')}</p>
                        <ImageUpload
                            handleFileChange={(file) => dispatch({
                                type: 'UPDATE',
                                value: file,
                                key: 'file',
                            })} />


                    </div>



                    <h2>{t('angebot_titel')}</h2>

                    <p>{t('angebot_text')}</p>

                    <Textarea
                        value={reducerState.philosophie}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'philosophie',
                            })}
                        placeholder={t('trainerfinder_philosopie')}
                    />




                    <h3>{t('Ausbildung')}</h3>
                    <Checkboxes items={js_ausbildung} checkedState={reducerState.ausbildung} dispatch={dispatch} keyName="ausbildung" />

                    <h3>{t('Erfahrung als Trainer/in')}</h3>
                    <Checkboxes items={erfahrung_options} checkedState={reducerState.erfahrung} dispatch={dispatch} keyName="erfahrung" />

                    <Textarea
                        value={reducerState.erfahrung_freitext}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'erfahrung_freitext',
                            })}
                        placeholder={t('erfahrung_freitext')}
                    />

                    <h3>{t('Disziplinen-Spezialisierung')}</h3>
                    <Checkboxes items={angebot} checkedState={reducerState.angebot} dispatch={dispatch} keyName="angebot" />

                    <h3>{t('Disziplinen-Spezialisierung')}</h3>
                    <Checkboxes items={sportarten} checkedState={reducerState.disziplinen} dispatch={dispatch} keyName="disziplinen" />


                    <div className="checkbox">
                        <input
                            type="checkbox"
                            id="datenschutz"
                            name="datenschutz"
                            value="datenschutz"
                            onChange={(e) => dispatch({
                                type: 'UPDATE',
                                value: e.target.checked,
                                key: 'datenschutz',
                            })
                            }
                        />
                        <label htmlFor="datenschutz" dangerouslySetInnerHTML={{ __html: t('datenschutz') }} />
                    </div>


                    <Button
                        type='submit'
                        value={t('speichern')}
                    />
                    {getUUID() ?
                        <button type="button" className="button" onClick={() => deleteEntry()}>Eintrag löschen</button> : null}
                    {error ? <div className="error">{error.message}</div> : null}
                    {success ? <div className="success">{t('Erfolgreich gespeichert')}.</div> : null}

                </form>

            </div ></div >
        <Footer />

    </>
    )
}
