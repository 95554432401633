import React, { createContext, useContext, useReducer, useState, useEffect } from 'react';
import createFuzzySearch from '@nozbe/microfuzz'
const FilterContext = createContext();

let fuzzySearch = null;

const filterReducer = (state, action, key) => {
    switch (action.type) {
        case 'SET_FILTER':
            return { ...state, ...action.payload };
        case 'RESET_FILTER':
            const newState = { ...state };
            Object.keys(newState).forEach(key => {
                if (Array.isArray(newState[key])) {
                    newState[key] = newState[key].map(item => ({
                        ...item,
                        visible: false
                    }));
                }
            });
            return newState;
        case 'TOGGLE_ITEM':
            const { value, key } = action;
            const itemIndex = state[key].findIndex((item) => item.value === value);
            if (itemIndex !== -1) {
                const updatedFilter = [...state[key]];
                updatedFilter[itemIndex] = {
                    ...updatedFilter[itemIndex],
                    visible: !updatedFilter[itemIndex].visible,
                };
                return { ...state, [key]: updatedFilter };
            }
            return state;
        default:
            return state;
    }
};

const initialState = {
    type: [],
    spielformen: [],
    stufen: [],
    erscheinungsformen: [],
    techniken: [],
    kanton: [],
    js_ausbildung: [],
    scg_ausbildung: [],
    sportart: [],
    availability: [],
    disziplinen: [],
    ausbildung: [],
    erf: [],
    wohnkanton: [],
    angebot: [],
};

const setFilter = (dispatch, filterData) => {
    dispatch({ type: 'SET_FILTER', payload: filterData });
};


const FilterProvider = ({ children }) => {
    const [filterState, dispatch] = useReducer(filterReducer, initialState);
    const [data, setData] = useState([]);
    const [visibleData, setVisibleData] = useState([]);
    const [searchString, setSearchString] = useState('');

    useEffect(() => {
        if (data?.length === 0) return;
        fuzzySearch = createFuzzySearch(data, {
            key: 'title',
        })
    }, [data]);


    const resetFilter = () => {
        dispatch({ type: 'RESET_FILTER' });
        setSearchString('');
    };

    useEffect(() => {
        if (searchString.length > 0) {
            const results = fuzzySearch(searchString)
            const filteredData = results.map((item) => item.item)
            setVisibleData(filteredData);
        } else {
            setVisibleData(data);
        }
    }, [searchString])

    const setFilterItems = (filterKey, filterItems) => {

        const tmpFilterItems = filterItems.map((item) => ({
            label: item?.name,
            value: item?.slug,
            visible: false,
        }));

        dispatch({
            type: 'SET_FILTER',
            payload: { [filterKey]: tmpFilterItems },
        })
    }

    const filterCategories = [
        'type',
        'spielformen',
        'stufen',
        'erscheinungsformen',
        'techniken',
        'kanton',
        'js_ausbildung',
        'scg_ausbildung',
        'sportart',
        'availability',
        'organisationsform',
        'angebot',
        'erfahrung',
        'wohnkanton',
        'disziplinen'
    ]

    const jobboerseFilterCategories = [
        'type',
        'spielformen',
        'stufen',
        'erscheinungsformen',
        'techniken',
        'kanton',
        'js_ausbildung',
        'scg_ausbildung',
        'sportart',
        'availability',
        'organisationsform',
    ];


    const trainerfinderFilterCategories = [
        'angebot',
        'erfahrung',
        'wohnkanton',
        'disziplinen'
    ];

    const options = {
        type: 'string',
        kanton: 'string',
        js_ausbildung: 'array',
        sportart: 'array',
        scg_ausbildung: 'array',
        availability: 'array',
        organisationsform: 'string',
        angebot: 'array',
        erfahrung: 'array',
        wohnkanton: 'string',
        disziplinen: 'array',
    }

    useEffect(() => {
        let visibleData = data;

        console.log(data)

        filterCategories.forEach((filterItem) => {
            const noneSelected = !filterState[filterItem]?.every((item) => item.visible === false);



            if (noneSelected && filterState[filterItem] !== undefined) {
                visibleData = visibleData.filter((item) => {
                    return filterState[filterItem]?.find((filterTag) => {

                        if (options[filterItem] === 'string') {
                            return (filterTag.value === item[filterItem] && filterTag.visible);
                        } else {
                            if (!item[filterItem]) return false;

                            let arr = item[filterItem];
                            if (!Array.isArray(item[filterItem])) {
                                arr = JSON.parse(item[filterItem]);
                            }

                            return arr?.some((filterTagItem) => {

                                return (filterTag.value === filterTagItem && filterTag.visible);
                            });

                        }

                    });

                });
            }
        })

        console.log(visibleData)


        setVisibleData(visibleData);
    }, [filterState, data])

    return (
        <FilterContext.Provider value={{ filterState, dispatch, setFilter, resetFilter, data, setData, setFilterItems, visibleData, searchString, setSearchString }}>
            {children}
        </FilterContext.Provider>
    );
};

const useFilter = () => {
    return useContext(FilterContext);
};

export { FilterProvider, FilterContext, useFilter };
