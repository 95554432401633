import React from 'react'

import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Item(props) {

    const { item, } = props;

    const { vorname, nachname, email, titel, organisation, beschreibung, type, plz, ort, ausbildung, scg_ausbildung, termine, organisationsform } = item;
    const date = new Date(item.date_created).toLocaleDateString('de-CH');

    let location = useLocation();

    const imageId = item.image?.id ? item.image.id : item.image;

    const isEdit = location.pathname.includes('eintrag-bearbeiten');

    const link = isEdit ? `/de/eintrag-bearbeiten/${item.id}` : `modal/${item.id}`
    const state = isEdit ? null : { background: location };
    const { t } = useTranslation('erfassen-seite');


    return (
        <div className="boerse-item" key={item.id}>

            <Link to={link} state={state} >
                <span className={`label ${(ausbildung?.includes('Weiterbildung 2 Leistungssport') || ausbildung?.includes('Trainerausbildung (BTA/DTA)')) && type === "biete" ? 'active' : null}`}>{t('Swiss Cycling zertifiziert')}</span>

                {imageId !== null &&

                    <div className="boerse-item__image-wrapper">
                        <img src={`https://api.swiss-cycling-jobboerse.ch/assets/${imageId}`} />

                    </div>

                }

                <div className="content">
                    <div>
                        <div className={"boerse-item__tag boerse-item__tag--" + type}>{type === "suche" ? t("suche") : t("biete")}</div><div className="boerse-item__tag boerse-item__tag--date">{date}</div>
                        <h2>{titel}</h2>
                    </div>

                    <div className="boerse-item__organisation">{organisation !== "" ? organisation + ", " + organisationsform + ", " : null}{plz} {ort}</div>
                    <span className="btn">{t('mehr erfahren')}</span>


                </div>




            </Link>
        </div>
    )
}
