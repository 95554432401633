import React, { useEffect, useReducer, useState } from 'react'
import '../../styles/pages/add-new.scss';
import Textfield from '../../components/Textfield';
import Button from '../../components/Button';
import Textarea from '../../components/Textarea';
import SelectButton from '../../components/SelectButton';
import Select from '../../components/Select';

import Header from '../../containers/header/Header';
import Footer from '../../containers/footer/Footer';

import { useNavigate, useParams, Link } from "react-router-dom";
import ImageUpload from './ImageUpload';
import { useTranslation } from 'react-i18next';

import { useError } from '../../context/ErrorContext';

import client from '../../client';
import { createItem, uploadFiles } from '@directus/sdk';

import i18n from '../../i18n';
import Checkboxes from '../../components/Checkboxes';

function getUUID() {
    const urlParams = new URLSearchParams(window.location.search);
    let uuid = urlParams.get('uuid');

    if (!uuid) {
        uuid = localStorage.getItem('uuid');
    } else {
        localStorage.setItem('uuid', uuid);
    }

    return uuid;
}

const options = [
    { value: 'none', text: 'Organisationsform', disabled: 'disabled', selected: 'defaultValue', hidden: 'hidden' },
    {
        value: 'Verein tätig im Radsport', text: 'Verein tätig im Radsport'
    },
    { value: 'Swiss Cycling Guide', text: 'Swiss Cycling Guide' },
    { value: 'Swiss Cycling Guide Partnerbetrieb', text: 'Swiss Cycling Guide Partnerbetrieb' },
]

const options2 = {
    de: [
        { value: 'none', text: 'Organisationsform', disabled: 'disabled', selected: 'defaultValue', hidden: 'hidden' },
        {
            value: 'Verein tätig im Radsport', text: 'Verein tätig im Radsport'
        },
        { value: 'Swiss Cycling Guide', text: 'Swiss Cycling Guide' },
        { value: 'Swiss Cycling Guide Partnerbetrieb', text: 'Swiss Cycling Guide Partnerbetrieb' },
    ],
    fr: [
        {
            value: 'none', text: "forme d'organisation", disabled: 'disabled', selected: 'defaultValue', hidden: 'hidden'
        },
        {
            value: 'Club active dans le cyclisme', text: "Club active dans le cyclisme"
        },
        { value: 'Swiss Cycling Guide', text: 'Swiss Cycling Guide' },
        {
            value: 'Entreprise partenaire du Swiss Cycling Guide', text: 'Entreprise partenaire du Swiss Cycling Guide'
        },
    ],
}

const sportarten2 = [
    { value: 'none', text: 'Sportart', disabled: 'disabled', selected: 'defaultValue', hidden: 'hidden' },
    { value: 'Bahnradsport', text: 'Bahnradsport' },
    { value: 'BMX', text: 'BMX' },
    { value: 'Einrad', text: 'Einrad' },
    { value: 'Kunstradfahren', text: 'Kunstradfahren' },
    { value: 'Mountainbike', text: 'Mountainbike' },
    { value: 'Radball', text: 'Radball' },
    { value: 'Radquer', text: 'Radquer' },
    { value: 'Strassenradsport', text: 'Strassenradsport' },
    { value: 'Trialradsport', text: 'Trialradsport' },
]

const sportarten = [
    "Bahnradsport",
    "BMX",
    "Einrad",
    "Kunstradfahren",
    "Mountainbike",
    "Radball",
    "Radquer",
    "Strassenradsport",
    "Trialradsport"
];


/*
const js_ausbildung = [
    { value: 'none', text: 'J+S Ausbildung', disabled: 'disabled', selected: 'defaultValue', hidden: 'hidden' },
    { value: 'Grundausbildung', text: 'Grundausbildung' },
    { value: 'Weiterbildung 1', text: 'Weiterbildung 1' },
    { value: 'Weiterbildung 2', text: 'Weiterbildung 2' },
    { value: 'Weiterbildung 2 Leistungssport', text: 'Weiterbildung 2 Leistungssport' },
    { value: 'Trainerausbildung (BTA/DTA)', text: 'Trainerausbildung (BTA/DTA)' },
    { value: 'Expertenausbildung', text: 'Expertenausbildung' },
]*/

const js_ausbildung = [
    "Grundausbildung",
    "Weiterbildung 1",
    "Weiterbildung 2",
    "Weiterbildung 2 Leistungssport",
    "Trainerausbildung (BTA/DTA)",
    "Expertenausbildung"
];

const scg_ausbildung = [
    "Road Guide Basic",
    "MTB Guide Basic",
    "MTB Guide Advanced",
    "Mountainbikelehrerin / Mountainbikelehrer mit eidgenössischem Fachausweis"
];


const initialState = {
    vorname: '',
    nachname: '',
    email: '',
    organisation: '',
    titel: '',
    beschreibung: '',
    type: 'suche',
    adresse: '',
    plz: '',
    ort: '',
    termine: '',
    js_ausbildung: [],
    scg_ausbildung: [],

}

function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state, [action.key]: action.value,
            }
        case 'REPLACE':
            return action.payload;
        default:
            return state
    }
}

const checkIfNotEmpty = (state) => {


    if (state.vorname === '' ||
        state.nachname === '' ||
        state.email === '' ||
        (state.organisation === '' && state.type === 'suche') ||
        (state.organisationsform === 'none' && state.type === 'suche') ||
        state.adresse === '' ||
        state.plz === '' ||
        state.ort === '' ||
        state.titel === '' ||
        state.beschreibung === '' ||
        (state.js_ausbildung.length === 0 && state.scg_ausbildung.length === 0) ||
        state.sportart.length === 0 ||
        state.availability.length === 0) {
        return false;
    }

    return true;
}

const availability = [
    "Wöchentlich",
    "Ca. alle zwei Wochen",
    "Monatlich",
    "Sporadisch als Aushilfe",
    "Bestimmter Zeitraum"
]


// const isProduction = process.env.REACT_APP_IS_PRODUCTION;
const isProduction = false;

export default function AddNew() {

    const { t } = useTranslation('erfassen-seite');

    const { language } = useParams();

    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { setErrorMessage, setSuccessMessage } = useError();

    const { id } = useParams();


    useEffect(() => {
        const uuid = getUUID();

        if (!uuid) {
            console.error('UUID is missing');
            return;
        }



        const fetchData = async () => {
            if (id === undefined) return;
            console.log(id)
            try {
                const payload = { uuid };

                const response = await fetch('https://api.swiss-cycling-jobboerse.ch/jobboerse/get', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const items = await response.json();

                const item = items.filter(item => item.id === parseInt(id));
                console.log(item)
                setReducerState(item[0]);

                //setData(items);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    const customJSONParse = (data) => {
        if (data.includes('[')) {
            return JSON.parse(data);
        } else {
            return [data];
        }
    }

    const setReducerState = (data) => {
        const tmpData = {
            ...data,
            js_ausbildung: customJSONParse(data.js_ausbildung),
            sportart: customJSONParse(data.sportart),
            scg_ausbildung: customJSONParse(data.scg_ausbildung),
            availability: customJSONParse(data.availability),
        }

        dispatch({
            type: 'REPLACE',
            payload: tmpData,
        })
    }




    const addFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        const result = await client.request(uploadFiles(formData));
        console.log(result);

        return result;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();


        if (!checkIfNotEmpty(reducerState)) {
            setErrorMessage('Bitte fülle alle Felder aus.');
            return;
        }

        if (reducerState.beschreibung.length < 50) {
            setErrorMessage('Die Beschreibung sollte mindestens 50 Zeichen lang sein.');
            return;
        }

        if (!reducerState.datenschutz) {
            setErrorMessage('Um deinen Eintrag einzureichen, musst du die Datenschutzbestimmungen akzeptieren.');
            return;
        }

        let file_id = null;
        if (reducerState.file) {
            const response = await addFile(reducerState.file);
            file_id = response.id;
        }





        const createPost = () => {
            client.request(createItem('posts', { ...reducerState, language: i18n.language, image: file_id }))
                .then(result => {
                    if (isProduction) {
                        let _paq = window._paq = window._paq || [];
                        _paq.push(['trackEvent', 'Neuer Eintrag', 'Neuer Eintrag', reducerState.type])
                    }
                    setSuccessMessage('Erfolgreich gespeichert.');
                    // navigate('/success')
                })
                .catch(error => {
                    setErrorMessage(error.message);
                });
        }

        const updatePost = async (reducerState, file_id) => {

            const uuid = localStorage.getItem('uuid');

            const payload = { ...reducerState };
            if (file_id) {
                payload.image = file_id;
            }


            const data = {
                payload: payload,
                uuid: uuid,
                id: id,
            };

            try {
                const response = await fetch('https://api.swiss-cycling-jobboerse.ch/jobboerse/put', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                if (response.ok) {
                    console.log('Post updated successfully');
                    setSuccessMessage('Erfolgreich gespeichert.');
                } else {
                    console.error('Failed to update post');
                    setErrorMessage('Failed to update post');
                }
            } catch (error) {
                console.error('Error updating post:', error);
                setErrorMessage('Failed to update post');

            }
        };

        if (id === undefined) {
            createPost();
        } else {
            updatePost(reducerState, file_id);
        }



    }

    useEffect(() => {


        if (reducerState.type === 'biete') {
            dispatch({
                type: 'UPDATE',
                value: t('biete_titel_placeholder'),
                key: 'titel',
            })
        } else {
            dispatch({
                type: 'UPDATE',
                value: '',
                key: 'titel',
            })
        }

    }, [reducerState.type])

    useEffect(() => {

        if (reducerState.plz.length < 4 || reducerState.plz.length > 4) {
            return;
        }

        const fetchPlz = async () => {
            fetch(`https://nominatim.openstreetmap.org/search?postalcode=${reducerState.plz}&country=Switzerland&format=json&addressdetails=1`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.length > 0) {
                    dispatch({
                        type: 'UPDATE',
                        value: data[0].address.state,
                        key: 'kanton',
                    })
                    const coordinates = { "type": "Point", "coordinates": [parseFloat(data[0].lon), parseFloat(data[0].lat)] }
                    dispatch({
                        type: 'UPDATE',
                        value: coordinates,
                        key: 'coordinates',
                    })
                }
            }).catch((err) => {
                console.log(err);
            });
        }

        fetchPlz();



    }, [reducerState.plz])

    const deleteEntry = async () => {



        const uuid = localStorage.getItem('uuid');

        const payload = { status: 'deleted' };

        const data = {
            payload: payload,
            uuid: uuid,
            id: id,
        };

        try {
            const response = await fetch('https://api.swiss-cycling-jobboerse.ch/jobboerse/put', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                setSuccessMessage('Erfolgreich gelöscht.');
                navigate('/de/eintrag-bearbeiten')

            } else {
                console.error('Failed to update post');
                setErrorMessage('Eintrag konnte nicht gelöscht werden.');
            }
        } catch (error) {
            console.error('Error updating post:', error);
            setErrorMessage('Failed to update post');

        }
    }



    return (<>
        <Header />

        <div className='add-new content'>
            <div className="content">

                {id === undefined ?
                    <div>


                        <h2>{t('erfassungsregeln_titel')}</h2>
                        <p>
                            <b>{t('erfassungsregeln_biete_fett')}</b> {t('erfassungsregeln_biete_text')}</p>
                        <p>
                            <b>{t('erfassungsregeln_suche_fett')}</b>  {t('erfassungsregeln_suche_text')}
                        </p>
                    </div>


                    : null}




                <form onSubmit={e => { handleSubmit(e) }}>
                    {id === undefined ?
                        <SelectButton value={reducerState.type} dispatch={dispatch} ichSuche={t('ich_suche_select')} ichBiete={t('ich_biete_select')} />
                        : null}
                    <h2>{t('kontakdaten_titel')}</h2>
                    <p>{t('kontakdaten_text')}</p>
                    <div className="columns-2">
                        <Textfield
                            value={reducerState.vorname}
                            onChange={(e) =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'vorname',
                                })}
                            placeholder={t('vorname')} />
                        <Textfield
                            value={reducerState.nachname}
                            onChange={(e) =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'nachname',
                                })}
                            placeholder={t('nachname')} />
                    </div>
                    <Textfield
                        value={reducerState.email}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'email',
                            })}
                        placeholder={t('Email')} />
                    {reducerState.type == 'suche' ?
                        <div>


                            <Textfield
                                value={reducerState.organisation}
                                onChange={(e) =>
                                    dispatch({
                                        type: 'UPDATE',
                                        value: e,
                                        key: 'organisation',
                                    })}
                                placeholder={t('organisation')} />

                            <Select options={options} value={reducerState.organisationsform} onChange={(e) =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'organisationsform',
                                })} />
                        </div> : null}

                    <Textfield
                        value={reducerState.adresse}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'adresse',
                            })}
                        placeholder={t('adresse')} />
                    <div className="columns-2">
                        <Textfield
                            value={reducerState.plz}
                            onChange={(e) =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'plz',
                                })}
                            placeholder={t('plz')} />
                        <Textfield
                            value={reducerState.ort}
                            onChange={(e) =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'ort',
                                })}
                            placeholder={t('ort')} />
                    </div>



                    <div>

                        <h2>{t('bild_titel')}</h2>
                        <p>{t('bild_text')}</p>
                        <ImageUpload
                            handleFileChange={(file) => dispatch({
                                type: 'UPDATE',
                                value: file,
                                key: 'file',
                            })} />


                    </div>



                    <h2>{t('angebot_titel')}</h2>

                    <p>{t('angebot_text')}</p>
                    <Textfield
                        value={reducerState.titel}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'titel',
                            })}
                        placeholder={t('titel')} />
                    {/* 
                    <Select options={sportarten} value={reducerState.sportart} onChange={(e) =>
                        dispatch({
                            type: 'UPDATE',
                            value: e,
                            key: 'sportart',
                        })} />*/}
                    <Textarea
                        value={reducerState.beschreibung}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'beschreibung',
                            })}
                        placeholder={reducerState.type === "suche" ? t('suche_beschreibung') : t('biete_beschreibung')}
                    />
                    {/*
                    <Select options={js_ausbildung} value={reducerState.js_ausbildung} onChange={(e) =>
                        dispatch({
                            type: 'UPDATE',
                            value: e,
                            key: 'js_ausbildung',
                        })} />


                    <Select options={scg_ausbildung} value={reducerState.scg_ausbildung} onChange={(e) =>
                        dispatch({
                            type: 'UPDATE',
                            value: e,
                            key: 'scg_ausbildung',
                        })} />
*/}

                    <h3>{t('Sportart')}</h3>
                    <Checkboxes items={sportarten} checkedState={reducerState.sportart} dispatch={dispatch} keyName="sportart" />



                    <h3>{reducerState.type === "biete" ? t('J+S Ausbildung') : t('gesuchte_js_ausbildung')} </h3>
                    <Checkboxes items={js_ausbildung} checkedState={reducerState.js_ausbildung} dispatch={dispatch} keyName="js_ausbildung" />


                    <h3>{reducerState.type === "biete" ? t('Swiss Cycling Guide Ausbildung') : t('gesuchte_scg_ausbildung')}</h3>
                    <Checkboxes items={scg_ausbildung} checkedState={reducerState.scg_ausbildung} dispatch={dispatch} keyName="scg_ausbildung" />


                    {false && reducerState.type === 'biete' && (reducerState.js_ausbildung.includes('Weiterbildung 2 Leistungssport') || reducerState.js_ausbildung.includes('Trainerausbildung (BTA/DTA)')) ?

                        <div className="trainer-block">
                            <h2>{t('trainer_titel')}</h2>

                            <p>{t('trainer_text')}</p>
                            <Textarea
                                value={reducerState.trainer_text_1}
                                onChange={(e) =>
                                    dispatch({
                                        type: 'UPDATE',
                                        value: e,
                                        key: 'trainer_text_1',
                                    })}
                                placeholder={t('trainer_text_1')}
                            />
                            <Textarea
                                value={reducerState.trainer_text_2}
                                onChange={(e) =>
                                    dispatch({
                                        type: 'UPDATE',
                                        value: e,
                                        key: 'trainer_text_2',
                                    })}
                                placeholder={t('trainer_text_2')}
                            />
                            <Textarea
                                value={reducerState.trainer_text_3}
                                onChange={(e) =>
                                    dispatch({
                                        type: 'UPDATE',
                                        value: e,
                                        key: 'trainer_text_3',
                                    })}
                                placeholder={t('trainer_text_3')}
                            />


                        </div>
                        : null

                    }


                    <h3>{reducerState.type === "biete" ? t('Verfügbarkeit') : t('gesuchte_verfuegbarkeit')}</h3>
                    <Checkboxes items={availability} checkedState={reducerState.availability} dispatch={dispatch} keyName="availability" />

                    {reducerState.availability && reducerState.availability.includes('Bestimmter Zeitraum') &&

                        <Textarea
                            value={reducerState.termine}
                            onChange={(e) =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'termine',
                                })}
                            placeholder={t('termine')}
                        />
                    }




                    <div className="checkbox">
                        <input
                            type="checkbox"
                            id="benachrichtigung"
                            name="benachrichtigung"
                            value="benachrichtigung"
                            checked={reducerState.benachrichtigung}
                            onChange={(e) => dispatch({
                                type: 'UPDATE',
                                value: e.target.checked,
                                key: 'benachrichtigung',
                            })
                            }
                        />
                        <label htmlFor="benachrichtigung"><strong>{t('benachrichtigung')}</strong></label>
                    </div>



                    <div className="checkbox">
                        <input
                            type="checkbox"
                            id="datenschutz"
                            name="datenschutz"
                            value="datenschutz"
                            onChange={(e) => dispatch({
                                type: 'UPDATE',
                                value: e.target.checked,
                                key: 'datenschutz',
                            })
                            }
                        />
                        <label htmlFor="datenschutz" dangerouslySetInnerHTML={{ __html: t('datenschutz') }} />
                    </div>


                    <Button
                        type='submit'
                        value={t('speichern')}
                    />
                    {getUUID() ?
                        <button type="button" className="button" onClick={() => deleteEntry()}>Eintrag löschen</button> : null}
                    {error ? <div className="error">{error.message}</div> : null}
                    {success ? <div className="success">{t('Erfolgreich gespeichert')}.</div> : null}

                </form>

            </div ></div >
        <Footer />

    </>
    )
}
