import logo from './logo.svg';
import './App.css';
import { Routes, Route, useLocation, Outlet } from "react-router-dom";
import Login from './containers/LoginForm/LoginForm';
import ProtectedRoute from './components/ProtectedRoute';
import Einstellungen from './pages/einstellungen/Einstellungen';
import Navigation from './containers/Navigation/Navigation';
import AddNew from './pages/addnew/AddNew';
import Boerse from './pages/boerse/Boerse';
import Success from './pages/sucess/Sucess';
import { useEffect } from 'react';

import Modal from './containers/modal/Modal';
import TrainerfinderModal from './containers/modal/TrainerfinderModal';

import { Navigate } from 'react-router-dom';
import i18n from './i18n';

import ErrorDisplay from './context/ErrorDisplay';
import EditOverview from './pages/edit-overview/EditOverview';
import Import from './pages/import/Import';

import RedirectToModal from './RedirectToModal';
import Impressum from './pages/impressum/Impressum';

import Trainerfinder from './pages/trainerfinder/Trainerfinder';
import AddNewTrainerItem from './pages/addnew/AddNewTrainerItem';

function App() {

  const location = useLocation();
  const background = location.state && location.state.background;

  return (
    <div className="App">
      <Routes location={background || location}>

        <Route path="/" element={<Navigate replace to="/de/" />} />

        <Route path="/:lang/eintrag-bearbeiten/:id" element={<AddNew />} />

        <Route path="/:lang">
          <Route path="login" element={<Login />} />
          <Route path="" element={<Boerse />} />
          <Route path="neuer-eintrag" element={<AddNew />} />
          <Route path="success" element={<Success />} />
          <Route path="impressum" element={<Impressum />} />

          <Route path="eintrag-bearbeiten" element={<EditOverview />} />
          <Route path="import/:id" element={<Import />} />
          <Route path="modal/:id" element={<Modal />} />
          <Route path=":id" element={<Boerse />} />



        </Route>
        <Route path="/trainerfinder" element={<Navigate replace to="/trainerfinder/de" />} />

        <Route path="/trainerfinder/:lang" >

          <Route path="login" element={<Login />} />
          <Route path="" element={<Trainerfinder />} />
          <Route path="neuer-eintrag" element={<AddNewTrainerItem />} />
          <Route path="success" element={<Success />} />
          <Route path="impressum" element={<Impressum />} />

          <Route path="eintrag-bearbeiten" element={<EditOverview />} />
          <Route path="import/:id" element={<Import />} />
          <Route path="modal/:id" element={<Modal />} />
          <Route path=":id" element={<Boerse />} />


        </Route>

      </Routes>

      {background && (
        <Routes>
          <Route path="/:lang/modal/:id" element={<Modal />} />
        </Routes>
      )}

      {background && (
        <Routes>
          <Route path="/trainerfinder/:lang/modal/:id" element={<TrainerfinderModal />} />
        </Routes>
      )}

      <Outlet />
      <ErrorDisplay />
    </div>
  );
}

export default App;
